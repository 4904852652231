(function () {
  'use strict';

  angular
    .module('events.vehicles.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.vehicles.add', {
        url: '/add',
        templateUrl: 'events/vehicles/edit/edit.tpl.html',
        controller: 'VehiclesEditCtrl',
        controllerAs: 'vm',
        resolve: {
          sections: function(Restangular) {
              return Restangular.all('sections').getList();
          }
        }
      })
      .state('events.vehicles.edit', {
        url: '/:vehicleId/edit',
        templateUrl: 'events/vehicles/edit/edit.tpl.html',
        controller: 'VehiclesEditCtrl',
        controllerAs: 'vm',
        resolve: {
          sections: function(Restangular) {
            return Restangular.all('sections').getList();
          }
        }
      })
    ;
  }
}());
